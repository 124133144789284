import { Component } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent {

  email: string = "";
  recaptcha_token: string = "not_set";
  isProcessing: boolean = false;
  isSubmitted: boolean = false;
 
  constructor(
    private authService: AuthService
    //private recaptchaV3Service: ReCaptchaV3Service
  ) { }
 
  ngOnInit(): void {
  }
 
  // reCAPTCHA v3 method
  executeRecaptcha(): void {
    //this.recaptchaV3Service.execute('forgot_password')
    //  .subscribe((token: string) => {
     //   this.recaptcha_token = token;
     this.recaptcha_token = "test";
    this.submitEmail();
    //  });
  }
 
  submitEmail(): void {
    this.isProcessing = true;
 
    this.authService.forgotPassword(this.email, this.recaptcha_token)
      .subscribe({
        next: (success: any) => {
          this.isSubmitted = success;
          this.isProcessing = false;
        }
      });
  }

}
