import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { PublicLayoutComponent } from './_layouts/public-layout/public-layout.component';
import { HeaderModule } from './_components/header/header.module';
import { FooterModule } from './_components/footer/footer.module';
import { AccountModule } from './account/account.module';
import { httpInterceptorProviders } from './_http-intercepters';
import { AuthenticatedLayoutComponent } from './_layouts/authenticated-layout/authenticated-layout.component';
import { DashboardModule } from './dashboard/dashboard.module';
import { SharedModule } from './shared/shared.module';
import { AuthService } from './_services/auth.service';
import { appInitializer } from './_helpers/app.initializer';

@NgModule({
  declarations: [
    AppComponent,
    PublicLayoutComponent,
    AuthenticatedLayoutComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    AccountModule,
    HeaderModule,
    FooterModule,
    DashboardModule,
    SharedModule
  ],
  providers: [
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY,
    //   useValue: environment.recaptcha.siteKey,
    // },
    httpInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService]
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
