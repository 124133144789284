<div class="nsw-col nsw-col-md-6">
  <form #changePasswordForm="ngForm" (ngSubmit)="changePasswordForm.valid && changePassword(model)"
    class="form-horizontal" id="changePasswordForm" role="form" name="changePasswordForm">

    <div class="nsw-form__group">
      <label class="nsw-form__label nsw-form__required" for="currentPassword">Current password</label>
      <input type="password" id="currentPassword" name="currentPassword" class="nsw-form__input"
        #currentPassword="ngModel" [(ngModel)]="model.Password" placeholder="Current password" required>
      <div
        *ngIf="currentPassword.invalid && (currentPassword.dirty || currentPassword.touched) && currentPassword.errors?.['required']"
        class="nsw-form__helper nsw-form__helper--error">
        <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
        <span>Current password is required.</span>
      </div>
    </div>

    <div class="nsw-form__group">
      <label class="nsw-form__label nsw-form__required" for="newPassword">New password</label>
      <input type="password" id="newPassword" name="newPassword" class="nsw-form__input" #newPassword="ngModel"
        [(ngModel)]="model.NewPassword" placeholder="New Password" required appPasswordStrength>
      <div *ngIf="newPassword.invalid && (newPassword.dirty || newPassword.touched)">
        <div class="nsw-form__helper nsw-form__helper--error" *ngIf="newPassword.errors?.['passwordStrength']">
          <div class="d-block">
            <div class="d-flex">
              <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
              Your password must contain:
            </div>
            <div>
              <ul style="margin-bottom: 0">
                <li>A minimum of 12 characters</li>
                <li>At least one uppercase character</li>
                <li>At least one lowercase character</li>
                <li>At least one number</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="nsw-form__group">
      <label class="nsw-form__label nsw-form__required" for="confimPassword">Confirm password</label>
      <input type="password" id="confimPassword" name="confirmPassword" class="nsw-form__input"
        #confimPassword="ngModel" [(ngModel)]="model.ConfirmPassword" placeholder="Confirm password" required>
      <div
        *ngIf="(confimPassword.invalid && (confimPassword.dirty || confimPassword.touched)) || model.NewPassword != model.ConfirmPassword"
        class="nsw-form__helper nsw-form__helper--error">
        <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
        Your passwords do not match
      </div>
    </div>

    <div class="nsw-form__group">
      <input type="submit" class="nsw-button nsw-button--dark" value="Change password" aria-label="Change Password" />
      <div class="pt-5">
        <div class="nsw-form__helper nsw-form__helper--valid"
          *ngIf="isSuccessful && passwordChangeStatus != null && passwordChangeStatus.length > 0">
          <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
          {{passwordChangeStatus}}
        </div>
        <div class="nsw-form__helper nsw-form__helper--error"
          *ngIf="!isSuccessful && passwordChangeStatus != null && passwordChangeStatus.length > 0">
          <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
          {{passwordChangeStatus}}
        </div>
      </div>
    </div>
  </form>
</div>
