import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(
    private router: Router
  ) {
    this.router.events.subscribe({
      next: (event: any) => {
        if (event instanceof NavigationEnd) {
          this.initNswDesignPoll();
          //this.initFeedbackAssist();
        }
      }
    });
  }

  initNswDesignPoll(): void {
    eval(`
      let stateCheck = setInterval(() => {
        if (document.readyState === 'complete') {
          clearInterval(stateCheck);
          // document ready
          window.NSW.initSite();
        }
      }, 100);

      // close main nav if open
      var nav = document.querySelectorAll('nav.nsw-main-nav.js-mega-menu.active')[0];
      if (nav !== undefined) {
        var menu = new NSW.Navigation(document.querySelectorAll('nav.nsw-main-nav.js-mega-menu.active')[0]);
        if (menu !== undefined) {
          var button = menu.closeNavButtons[0];
          if (button !== undefined) {
            button.click();
          }
        }
      }
    `);
  }
}
