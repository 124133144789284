<h1>Home</h1>
<!-- <p style="font-size: 1.4em;">Welcome to the Impact Pathways Login Portal.</p> -->
<p style="font-size: 1.4em;">Welcome to the Sustainable Government Portal.</p>


<div class="nsw-h2">My applications</div>
<p>Click to navigate to one of your applications.</p>
<!-- <p>Note: You'll be redirected away from the Impact Pathways Portal.</p> -->
<p>Note: You'll be redirected away from this Portal.</p>

<div class="nsw-section">
  <div *ngIf="isLoadingResults">
    <mat-spinner style="margin:0 auto;" diameter="30"></mat-spinner>
  </div>

  <div class="nsw-grid nsw-grid--spaced">
    <div class="nsw-col nsw-col-md-6 nsw-col-lg-3" *ngFor="let e of dataSource">
      <div class="nsw-card nsw-card--headline" (click)="navigateToModule(e.clientId)">
        <div class="nsw-card__content clickable">
          <h2 class="nsw-card__title">
            <h2 class="nsw-card__title">
              <!-- <a href="{{e.clientEndpoint}}" class="nsw-card__link">{{e.moduleName}}</a> -->
              {{e.moduleName}}
            </h2>
          </h2>
          <p class="nsw-card__copy"></p>
          <i class="material-icons nsw-material-icons nsw-card__icon" focusable="false" aria-hidden="true">east</i>
        </div>
      </div>
    </div>
  </div>
</div>


<div *ngIf="isAdmin">
  <div class="nsw-h2">Admin Functions</div>
  <p>Click to navigate to an admin function.</p>

  <div class="nsw-section">
    <div class="nsw-grid nsw-grid--spaced">
      <div class="nsw-col nsw-col-md-6 nsw-col-lg-3">
        <div class="nsw-card nsw-card--headline">
          <div class="nsw-card__content clickable">
            <h2 class="nsw-card__title">
              <h2 class="nsw-card__title">
                <a [routerLink]="['/admin/users/list']" class="nsw-card__link">User admin</a>
              </h2>
            </h2>
            <p class="nsw-card__copy"></p>
            <i class="material-icons nsw-material-icons nsw-card__icon" focusable="false" aria-hidden="true">east</i>
          </div>
        </div>
      </div>

      <div class="nsw-col nsw-col-md-6 nsw-col-lg-3">
        <div class="nsw-card nsw-card--headline">
          <div class="nsw-card__content clickable">
            <h2 class="nsw-card__title">
              <h2 class="nsw-card__title">
                <a [routerLink]="['/admin/modules/list']" class="nsw-card__link">Application admin</a>
              </h2>
            </h2>
            <p class="nsw-card__copy"></p>
            <i class="material-icons nsw-material-icons nsw-card__icon" focusable="false" aria-hidden="true">east</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
