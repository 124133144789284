import { Component } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { Router } from '@angular/router';
import { PortalModuleService } from '../portal-modules/_services/portal-module.service';
import { UserModule } from '../portal-modules/_models/user-module';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {

  dataSource?: UserModule[];
  isLoadingResults: boolean = true;
  isAdmin: boolean = false;

  constructor(
    private authService: AuthService,
    private moduleService: PortalModuleService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (!this.dataSource) {
      this.isLoadingResults = true;
    }
    this.loadModules(this.authService.getUserId());
    this.getIsAdmin();
  }

  loadModules(userId: number): void {
    if (userId > 0) {
      this.isLoadingResults = true;
      this.moduleService
        .getUserModules(userId)
        .subscribe({
          next: (data: UserModule[]) => {
            this.dataSource = data;
            this.isLoadingResults = false;
          }
        });
    }
    else {
      this.isLoadingResults = false;
    }
  }

  getIsAdmin(): void {
    this.isAdmin = this.authService.userHasAnyRole('System Administrator', 'Administrator');
  }

  navigateToModule(clientId?: string) {
    if (clientId) {
      this.moduleService
        .getClientEndpoint(clientId)
        .subscribe({
          next: (data) => {
            if (data && data.returnUrl) {
              window.location.href = data.returnUrl;
            } else {
              alert('An error occurred while trying to navigate to the module.');
            }
          },
          error: (error) => {
            console.error(error);
            alert('An error occurred while trying to navigate to the module.');
          }
        });
    }
  }
}
