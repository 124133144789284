import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(
    public authService: AuthService,
    public router: Router) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      console.log("authGuardService.canActivate not isAuthenticated, clearing user tokens now...");
      this.authService.clearCurrentUser();
      this.router.navigate(["/login"]);
      return false;
    } else {
      //check if user needs to change their password.
      if (route.url && (route.url.length === 0 || route.url[0].path != "profile/changepassword")) {
        if (this.authService.needsPasswordChange()) {
          this.router.navigate(["/profile/changepassword"]);
          return false;
        }
      }
    }

    return true;
  }
}
