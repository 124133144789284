<div class="nsw-col nsw-col-md-6">
    <h1>Forgot password?</h1>
    <h4>If you've forgotten your password, you can reset it here.</h4>
    <br />
    <p>Please enter your email address, then retrieve the link we email to you to reset your password.</p>
    <br />

    <form #forgotForm="ngForm" (ngSubmit)="forgotForm.valid && executeRecaptcha()"
          class="form-horizontal" id="forgot-password-form" role="form" name="forgot">
      <div class="nsw-form__group">
        <label class="nsw-form__label nsw-form__required" for="email">Email</label>
        <input type="email" class="nsw-form__input" id="email" placeholder="Email address"
               name="Email" [(ngModel)]="email" required
               [attr.aria-invalid]="forgotForm.controls['Email'] && forgotForm.controls['Email'].invalid && (forgotForm.controls['Email'].dirty || forgotForm.controls['Email'].touched)" />
        <span *ngIf="forgotForm.controls['Email']?.invalid && (forgotForm.controls['Email'].dirty || forgotForm.controls['Email'].touched)"
              class="nsw-form__helper nsw-form__helper--error" id="form-text-3-error-text">
          <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
          This field is required
        </span>
      </div>
  
      <div class="nsw-form__group">
        <input type="submit" value="Submit" aria-label="Submit" class="nsw-button nsw-button--dark"
               [disabled]="isProcessing" *ngIf="!isSubmitted" />
  
        <div *ngIf="isSubmitted" class="alert alert-info">
          <p>Your request has been submitted.</p>
          <p>If this email address is registered, you will be sent a link to set a new password.</p>
        </div>
  
        <div *ngIf="isSubmitted">
          <div class="pt-5"></div>
          <a class="nsw-button nsw-button--dark" [routerLink]="['/']">Go to home</a>
        </div>
      </div>

      <div class="nsw-in-page-alert nsw-in-page-alert--info nsw-in-page-alert--compact">
        <span class="material-icons nsw-material-icons nsw-in-page-alert__icon" focusable="false"
          aria-hidden="true">info</span>
        <div class="nsw-in-page-alert__content">
          <p class="nsw-small">If you do not receive our email, please check your junk or spam email folders.</p>
        </div>
      </div>
    </form>
  </div>
  