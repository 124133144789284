import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';
import { UserRegisterModel } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  serviceUrl = baseUrl + '/user';

  constructor(
    private http: HttpClient,
  ) { }

  getUserProfile(): Observable<UserRegisterModel> {
    return this.http.post<UserRegisterModel>(this.serviceUrl + '/getUserProfile', {});
  }

  updateUser(model: UserRegisterModel): Observable<boolean> {
    return this.http.post<boolean>(this.serviceUrl + '/update', model);
  }

  verifyEmail(userId: number, code: string): Observable<boolean> {
    return this.http.post<boolean>(baseUrl + '/auth/VerifyEmail', null, {
      params: {
        userId: userId,
        code: code
      }
    });
  }
}
