import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BaseService } from 'src/app/_services/base.service';
import { LoggerService } from 'src/app/_services/logger.service';
import { UserModule } from '../_models/user-module';

@Injectable({
  providedIn: 'root'
})
export class AuthorisationService extends BaseService {

  private readonly serviceUrl: string = this.baseUrl + '/Authorisation/';

  constructor(
    private http: HttpClient,
    private router: Router,
    private logger: LoggerService
  ) {
    super(router, logger);
  }

  grantAccess(model: UserModule): Observable<UserModule> {
    return this.http.post<UserModule>(this.serviceUrl + 'grantAccess', model);
  }

  revokeAccess(model: UserModule): Observable<UserModule> {
    return this.http.post<UserModule>(this.serviceUrl + 'revokeAccess', model);
  }
}
