import { HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpHandler, HttpEvent, HttpRequest, HttpHeaders, HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { tap, catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { AuthService } from "../_services/auth.service";

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(`AuthenticationInterceptor - ${req.url}`);

    let jsonReq: HttpRequest<any> = req.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: `Bearer ${localStorage.getItem(this.authService.getAccessTokenName())}`
      }
    });

    return next.handle(jsonReq)
      .pipe(
        tap((data: HttpEvent<any>) => {
          // console.log(`AuthenticationInterceptor - ${req.url}`);
          if (data instanceof HttpResponse) {
            // do stuff with response if you want
            // potentially display a label in the header when the token is about to expire...20 mins maybe?
          }
        }),
        catchError(this.handleError<any>(`${req.url}`))
      );
  }

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // if (error instanceof HttpErrorResponse) {
      // 	if (error.status === 401) {
      // 		// redirect to the login route
      // 		this.router.navigate(["home"]);
      // 	}
      // }

      if (error instanceof HttpErrorResponse) {
        if (error.status === 403) { // Forbidden
          // redirect to the login route
          // this.router.navigate(["home"]);
          alert('forbidden request');
        } else {
          throw error;
        }
      }

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
