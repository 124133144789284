<div class="nsw-col nsw-col-md-6">
    <h1>Reset password</h1>
    <div class="heading-4">Provide your username and a new password to reset.</div>
    <br />
    <form #resetPasswordForm="ngForm" (ngSubmit)="resetPasswordForm.valid && resetPassword(model)"
        class="form-horizontal" id="resetPasswordForm" role="form" name="resetPasswordForm">

        <div class="nsw-form__group">
            <label class="nsw-form__label nsw-form__required" for="email-address">Email</label>
            <input type="email" id="email" name="email" class="nsw-form__input" #email="ngModel"
                [(ngModel)]="model.Email" placeholder="Email address" email required>

            <div *ngIf="email.invalid && (email.dirty || email.touched) && email.errors?.['required']"
                class="nsw-form__helper nsw-form__helper--error">
                <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
                <span>Email address is required.</span>
            </div>
            <div *ngIf="email.invalid && (email.dirty || email.touched) && email.invalid"
                class="nsw-form__helper nsw-form__helper--error">
                <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
                <span>Email address is invalid.</span>
            </div>
        </div>

        <div class="nsw-form__group">
            <label class="nsw-form__label nsw-form__required" for="password">New password</label>
            <input type="password" id="password" name="password" class="nsw-form__input" #password="ngModel"
                [(ngModel)]="model.Password" placeholder="Password" required appPasswordStrength>
            <div *ngIf="password.invalid && (password.dirty || password.touched)">
                <div class="nsw-form__helper nsw-form__helper--error" *ngIf="password.errors?.['passwordStrength']">
                    <div class="d-block">
                        <div class="d-flex">
                          <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
                          Your password must contain:
                        </div>
                        <div>
                          <ul style="margin-bottom: 0">
                            <li>A minimum of 12 characters</li>
                            <li>At least one uppercase character</li>
                            <li>At least one lowercase character</li>
                            <li>At least one number</li>
                          </ul>
                        </div>
                      </div>
                </div>
            </div>
        </div>

        <div class="nsw-form__group">
            <label class="nsw-form__label nsw-form__required" for="confimPassword">Confirm password</label>
            <input type="password" id="confimPassword" name="confirmPassword" class="nsw-form__input"
                #confimPassword="ngModel" [(ngModel)]="model.ConfirmPassword" placeholder="Confirm password" required>
            <div *ngIf="(confimPassword.invalid && (confimPassword.dirty || confimPassword.touched)) || model.Password != model.ConfirmPassword"
                class="nsw-form__helper nsw-form__helper--error">
                <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
                Your passwords do not match
            </div>
        </div>

        <div class="nsw-form__group">
            <input type="submit" class="nsw-button nsw-button--dark" value="Reset password"
                aria-label="Reset Password" />
            <div class="pt-5">
                <div class="nsw-form__helper nsw-form__helper--valid"
                    *ngIf="isSuccessful && passwordChangeStatus != null && passwordChangeStatus.length > 0">
                    <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
                    {{passwordChangeStatus}}
                </div>
                <div class="nsw-form__helper nsw-form__helper--error"
                    *ngIf="!isSuccessful && passwordChangeStatus != null && passwordChangeStatus.length > 0">
                    <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
                    {{passwordChangeStatus}}
                </div>

                <div *ngIf="isSuccessful">
                    <a class="nsw-button nsw-button--dark" [routerLink]="['/']">Sign in</a>
                </div>
            </div>
        </div>
    </form>
</div>