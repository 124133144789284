import { SessionRequest } from "./session-request";

export class Login {
  username!: string;
  password!: string;
  rememberMe!: boolean;
  twoFactorCode?: string;
  recaptcha_token?: string;
  sessionRequest?: SessionRequest;
}
