import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './_components/change-password/change-password.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { FormsModule } from '@angular/forms';
import { AccountRoutingModule } from './account-routing.module';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PasswordStrengthDirective } from '../_directives/password-strength.directive';
import { ProfileComponent } from './profile/profile.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ChangeAccountPasswordComponent } from './change-account-password/change-account-password.component';
import { LogoutComponent } from './logout/logout.component';


@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    VerifyEmailComponent,
    ResetPasswordComponent,
    PasswordStrengthDirective,
    ProfileComponent,
    ChangeAccountPasswordComponent,
    LogoutComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    AccountRoutingModule,
    MatProgressSpinnerModule
  ]
})
export class AccountModule { }
