import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { baseUrl, environment } from 'src/environments/environment';
import { LoggerService } from './logger.service';
import { jwtDecode } from 'jwt-decode';

@Injectable({
  providedIn: 'root'
})
export class BaseService {

  //local variables are set from variables of the same name in the environment file.
  baseUrl = baseUrl;
  environment = environment;

  protected readonly ACCESS_TOKEN_NAME: string = "token";
  protected readonly ACCESS_TOKEN_EXPIRATION_NAME: string = "tokenexpiration";

  constructor(
    private routerService: Router,
    private loggerService: LoggerService) { }

    public getAccessTokenName(): string {
      return this.ACCESS_TOKEN_NAME;
    }
  
    public getExpirationTokenName(): string {
      return this.ACCESS_TOKEN_EXPIRATION_NAME;
    }

  protected getLoginId(): number {
    // decode the token to get its payload
    const token: string = localStorage.getItem(this.getAccessTokenName()) ?? "";
    const tokenPayload: any = jwtDecode(token);
    const id = tokenPayload["nameId"];
    return (id != null) ? id : 0;
  }

  protected handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          // remove expired tokens and redirect to the login route.
          localStorage.removeItem(this.getAccessTokenName());
          localStorage.removeItem(this.getExpirationTokenName());
          //this.routerService.navigate(["landing"]); //todo
        }
      }

      // TODO: send the error to remote logging infrastructure
      this.log(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  protected log(message: string) {
    this.loggerService.log(message);
  }
}
