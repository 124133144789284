<div class="nsw-col nsw-col-md-6">
    <h1>Verify Email</h1>
  
    <div class="nsw-form__group">
      <div *ngIf="isLoggedIn && isVerified">
        <p>Success! Your email has been verified. Go to your Dashboard to get started.</p>
      </div>
  
      <div *ngIf="!isLoggedIn && isVerified">
        <p>Success! Your email has been verified. You can now log in.</p>
      </div>
  
      <div *ngIf="!isVerified">
        <p>We were unable to confirm your email account.</p>
      </div>
    </div>
  
    <div class="nsw-form__group">
      <a class="btn btn-primary-cta" [routerLink]="['/admin/dashboard']" *ngIf="isLoggedIn && isVerified">Go to Dashboard</a>
      <a class="btn btn-primary-cta" [routerLink]="['/login']" *ngIf="!isLoggedIn || !isVerified">Log in</a>
    </div>
  </div>
