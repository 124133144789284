export class User {
  id!: number;

  firstName?: string;

  lastName?: string;

  FullName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  phone?: string;

  organisation?: string;

  acceptTerms?: boolean | null;

  created?: Date | null;

  createdBy?: number | null;

  updated?: Date | null;

  updatedBy?: number | null;

  email?: string;

  emailConfirmed?: boolean;

  userName?: string;

  isAccountLocked?: boolean | null;

  accountLocked?: boolean | null;

  accountLockedUntil?: Date | null;

  enforceNewPassword?: boolean | null;

  passwordChanged?: Date | null;
}

export class UserRegisterModel {
  email?: string;

  phone?: string;

  organisation?: string;

  password?: string;

  confirmPassword?: string;

  firstName?: string;

  lastName?: string;

  acceptTerms?: boolean | null;

  role?: number | null;

  roles?: number[];

  userId?: string;

  Recaptcha_token?: string;

  providerId1?: number | null;

  providerId2?: number | null;
}

export class UserType {
  id?: number;

  userType?: string;
}

export class ResetPassword {
  Email?: string;

  Password?: string;

  ConfirmPassword?: string;

  NewPassword?: string;

  Code?: string;
}
