/* "Barrel" of Http Interceptors */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenticationInterceptor } from './AuthenticationInterceptor';
// import { CacheInterceptor } from './CacheInterceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
	// { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
	{ provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true }
];
