import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  isEnabled: boolean = false;

  constructor() {
    if (environment.production) {
      this.isEnabled = false;
    } else {
      this.isEnabled = false;
    }
  }

  public setEnabled(isEnabled: boolean) {
    this.isEnabled = isEnabled;
  }

  public log(message: string) {
    if (this.isEnabled)
      console.log(message);
  }
}
