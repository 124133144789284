import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { Observable, catchError, map, tap } from 'rxjs';
import { LoggerService } from './logger.service';
import { Login } from '../_models/login';
import { BaseService } from './base.service';
import { Role } from '../_models/role';
import { ResetPassword, User } from '../_models/user';
import { AuthResponse } from '../_models/auth-response';
import { AuthRevokeToken } from '../_models/auth-revoke-token';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {

  private readonly serviceUrl: string = this.baseUrl + '/auth/';

  _currentUser: any;
  _isLoggedIn: boolean = false;
  _isEmailConfirmed: boolean = false;

  public authenticatedStatusChanged$: EventEmitter<AuthResponse>;

  constructor(
    private http: HttpClient,
    private router: Router,
    private logger: LoggerService
  ) {
    super(router, logger);

    this.authenticatedStatusChanged$ = new EventEmitter();
  }

  public onAuthenticatedStatusChanged(loginResponse: AuthResponse): void {
    this.authenticatedStatusChanged$.emit(loginResponse);
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem(this.ACCESS_TOKEN_NAME);

    if (!token) {
      return false;
    }

    return !this.tokenExpired(token);
  }

  // alternate https://jasonwatmore.com/post/2020/05/22/angular-9-jwt-authentication-with-refresh-tokens
  private tokenExpired(token: string) {
    const expiry = (JSON.parse(window.atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date).getTime() / 1000)) >= expiry;
  }

  public needsPasswordChange(): boolean {
    var jwtToken = this.getJwtToken();
    if (!jwtToken)
      return false;

    var enforceNewPassword = jwtToken["EnforceNewPassword"];
    return enforceNewPassword == "True" || enforceNewPassword == "true";
  }

  login(model: Login): Observable<AuthResponse> {
    return this.http.post(this.serviceUrl + 'Login', model)
      .pipe(map((response: any) => {
        //jwt token is only returned for authenticated accounts, that are
        //also not currently locked, and 2fa is not currently enabled.
        // login2fa() is called if 2fa is enabled.
        if (response && response.isAuthenticated && !response.isAccountLocked && !response.isTwoFactorEnabled) {
          this.saveJwtToken(response);
        }
        return response;
      }));
  }

  login2FA(model: Login): Observable<AuthResponse> {
    return this.http.post(this.serviceUrl + 'Login2FA', model)
      .pipe(map((response: any) => {
        //jwt token is only returned for authenticated accounts that is not currently locked,
        //and the 2fa authenticator code has been verified.
        // login() is called if 2fa is disabled.
        if (response && response.isAuthenticated && !response.isAccountLocked
          && response.isTwoFactorEnabled && response.isTwoFactorVerified) {
          this.saveJwtToken(response);
        }
        return response;
      }));
  }

  resendMfaCode(model: Login): Observable<boolean> {
    return this.http.post<boolean>(this.serviceUrl + 'ResendMfaCode', model);
  }

  saveJwtToken(response: AuthResponse): void {
    localStorage.setItem(this.ACCESS_TOKEN_NAME, response.jwtToken);
    localStorage.setItem(this.ACCESS_TOKEN_EXPIRATION_NAME, response.jwtTokenExpiration.toString());
    //this.roleService.initialise();
    this.startRefreshTokenTimer();
    this.onAuthenticatedStatusChanged(response);
  }

  logout(): Observable<void> {
    return this.http.post<any>(this.serviceUrl + 'RevokeToken', null)
      .pipe(map((response: any) => {  
        this.clearCurrentUser();

        var loginResponse = new AuthResponse();
        loginResponse.isAuthenticated = false;
        this.onAuthenticatedStatusChanged(loginResponse);
        //this.router.navigate(['/']);

        return response;
      }));
  }

  getRefreshToken(): Observable<AuthResponse> {
    var date = new Date();
    console.log("getRefreshToken() about to run..." + date.toString());

    return this.http.post<any>(this.serviceUrl + 'GetRefreshToken', {}, { withCredentials: true })
      .pipe(map((response: AuthResponse) => {
        if (response) {
          localStorage.setItem(this.ACCESS_TOKEN_NAME, response.jwtToken);
          localStorage.setItem(this.ACCESS_TOKEN_EXPIRATION_NAME, response.jwtTokenExpiration.toString());
          this.startRefreshTokenTimer();
        }
        return response;
      }),
        catchError<any, any>((operation: any, result?: any) => {
          this.clearCurrentUser();
        })
      );
  }

  revokeUserTokens(userId: number): Observable<boolean> {
    var revoke = new AuthRevokeToken();
    revoke.UserId = userId;
    return this.http.post<boolean>(this.serviceUrl + 'RevokeUserTokens', revoke);
  }

  revokeAllTokens(): Observable<boolean> {
    return this.http.post<boolean>(this.serviceUrl + 'RevokeAllTokens', {});
  }

  // todo: we might not want to implement this anyway
  // deleteAllRevokedTokens(): Observable<boolean> {
  //   //sysAdmin only function.
  //   return this.http.post<boolean>(this.serviceUrl + 'DeleteAllRevokedTokens', {});
  // }

  // todo: we might not want to implement this anyway
  // deleteAllExpiredTokens(): Observable<boolean> {
  //   //sysAdmin only function.
  //   return this.http.post<boolean>(this.serviceUrl + 'DeleteAllExpiredTokens', {});
  // }

  currentUser(): Observable<any> {
    if (this._currentUser != null) {
      return this._currentUser;
    }
    else {
      return this.getCurrentUser();
    }
  }

  public clearCurrentUser(): void {
    this.stopRefreshTokenTimer();
    localStorage.removeItem("programredirect"); // whats this?
    localStorage.removeItem(this.getAccessTokenName());
    localStorage.removeItem(this.getExpirationTokenName());
    this._currentUser = null;
  }

  getCurrentUser(): Observable<User> {
    return this.http.post<User>(this.baseUrl + '/user/' + 'GetUserProfile', null)
      .pipe(
        tap(data => {
          this._isLoggedIn = false;
          this._isEmailConfirmed = false;
          this._currentUser = null;
        }),
        catchError(this.handleError<any>('getCurrentUser'))
      );
  }


  getUserId(): number {
    const token: any = localStorage.getItem(this.getAccessTokenName());
    if (token) {
      const tokenPayload: any = jwtDecode(token);
      return tokenPayload['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier']
    } else {
      return -1;
    }
  }

  getIsEmailConfirmed(): Observable<boolean> {
    return this.http.get<boolean>(this.serviceUrl + 'IsEmailConfirmed');
  }

  forgotPassword(email: string, recaptcha_token: string): Observable<boolean> {
    return this.http.post<boolean>(this.serviceUrl + 'SendForgotPassword', null, {
      params: {
        email: email,
        recaptcha_token: recaptcha_token
      }
    });
  }

  resetPassword(model: ResetPassword): Observable<boolean> {
    return this.http.post<boolean>(this.serviceUrl + 'ResetPassword', model);
  }

  changePassword(model: ResetPassword): Observable<any> {
    return this.http.post<any>(this.serviceUrl + 'UpdatePassword', model);
  }

  userHasAnyRole(...role: string[]): boolean {
    for (let r of role) {
      if (this.userHasRole(r)) {
        return true;
      }
    }
    return false;
  }

  userHasRole(role: string): boolean {
    const roles = this.getUserRolesFromToken();
    if (roles) {
      for (let r of roles) {
        if (r === role) {
          return true;
        }
      }
    }
    return false;
  }

  getUserRolesFromToken(): string[] | undefined {
    const token: any = localStorage.getItem(this.getAccessTokenName());
    if (token) {
      const tokenPayload: any = jwtDecode(token);
      const roles = tokenPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'];
      if (roles) {
        return tokenPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'].toString().split(',');
      }
    }
    return undefined;
  }

  // getUserRolesFromToken(): Role[] {
  //   const token: any = localStorage.getItem(this.getAccessTokenName());
  //   if (token) {
  //     const tokenPayload: any = decode(token);
  //     return <Role[]>tokenPayload['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'] ?? new Array<Role>();
  //   } else {
  //     return new Array<Role>();
  //   }
  // }

  getUserRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(this.serviceUrl + 'GetUserRoles');
  }

  isSysAdmin(): boolean {
    return this.userHasRole('System Administrator');
  }

  isAdmin(): boolean {
    return this.userHasRole('Administrator');
  }

  // helper methods

  private refreshTokenTimeout?: ReturnType<typeof setTimeout>;

  private startRefreshTokenTimer() {
    var jwtToken = this.getJwtToken();
    if (!jwtToken)
      return;

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - (60 * 1000);
    console.log("access token should expire at: " + new Date(expires));
    console.log("access token should refresh again at: " + new Date(expires.getTime() - (60 * 1000)));
    this.refreshTokenTimeout = setTimeout(() => this.getRefreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  public getJwtToken(): any {
    // decode the token to get its payload
    const token: any = localStorage.getItem(this.getAccessTokenName());
    if (!token)
      return null;

    const tokenPayload: any = jwtDecode(token);
    return tokenPayload;
  }

  public getLoginDisabledMessage(): string {
    return "'Sign in' has been temporarily disabled due to the app currently undergoing maintenance. Please come back later.";
  }
}
