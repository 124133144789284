<div *ngIf="isLoadingResults">
    <mat-spinner style="margin:0 auto;"></mat-spinner>
  </div>

<div class="nsw-table nsw-table--striped" tabindex="0">
    <table mat-table *ngIf="dataSource" [dataSource]="dataSource">

      <!-- mat-header-cell -->
      <!-- mat-cell -->

      <!-- <ng-container matColumnDef="id">
        <th *matHeaderCellDef> Id </th>
        <td *matCellDef="let element"> {{element.id}} </td>
      </ng-container>
   -->
      <ng-container matColumnDef="name">
        <th *matHeaderCellDef> Name </th>
        <td *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th *matHeaderCellDef> </th>
        <td *matCellDef="let element">
            <a href="javascript: void(0);" (click)="addModule(element.id)" *ngIf="!(element.userId > 0)">Add access</a>
            <a href="javascript: void(0);" (click)="removeModule(element.id)" *ngIf="element.userId > 0">Remove access</a>
            <span *ngIf="element.userId > 0">&nbsp;|&nbsp;</span>
            <a href="javascript: void(0);" (click)="goToClientPermissionEndpoint(element.id, element.clientId)" *ngIf="(element.userId > 0) && (element.id === 1 || element.id === 2)">Edit client Permissions</a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>
