import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from '../../_services/base.service';
import { Router } from '@angular/router';
import { LoggerService } from '../../_services/logger.service';
import { Observable } from 'rxjs';
import { PortalModule } from '../../_models/portal-module';
import { UserModule } from '../_models/user-module';
import { SessionRequest } from 'src/app/_models/session-request';
import { SessionResponse } from 'src/app/_models/session-response';

@Injectable({
  providedIn: 'root'
})
export class PortalModuleService extends BaseService {

  private readonly serviceUrl: string = this.baseUrl + '/Module/';

  constructor(
    private http: HttpClient,
    private router: Router,
    private logger: LoggerService
  ) {
    super(router, logger);
  }

  getCurrentUserModules(): Observable<PortalModule[]> {
    return this.http.get<PortalModule[]>(this.serviceUrl + 'getAllActiveModules');
  }

  getAllModules(): Observable<PortalModule[]> {
    return this.http.get<PortalModule[]>(this.serviceUrl + 'getAllActiveModules');
  }

  getUserModules(userId: number): Observable<UserModule[]> {
    return this.http.get<UserModule[]>(this.serviceUrl + 'getModulesForUser', {
      params: {
        userId: userId
      }
    });
  }

  getModule(moduleId: number): Observable<PortalModule> {
    return this.http.get<PortalModule>(this.serviceUrl + 'getModule', {
      params: {
        moduleId: moduleId
      }
    });
  }

  createModule(model: PortalModule): Observable<PortalModule> {
    return this.http.post<PortalModule>(this.serviceUrl + 'CreateModule', model);
  }

  getClientEndpoint(clientId?: string, returnUrl?: string): Observable<SessionResponse> {
    let request = new SessionRequest();
    request.clientId = clientId;
    request.returnUrl = returnUrl;
    return this.http.post<SessionResponse>(this.baseUrl + '/auth/getClientEndpoint', request);
  }

  getClientPermissionEndpoint(moduleId?: number): Observable<any> {
    return this.http.get(this.serviceUrl + 'getClientPermissionEndpoint', {
      responseType: 'text',
      params: {
        moduleId: moduleId!
      }
    });
  }
}
