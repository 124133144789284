import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResetPassword } from 'src/app/_models/user';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent implements OnInit {

  code?: string;

  model: ResetPassword = new ResetPassword();
  isSuccessful = false;
  passwordChangeStatus: string = "";

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.code = params["code"];
      this.model.Code = this.code;
    });
  }

  resetPassword(model: ResetPassword): void {
    this.authService.resetPassword(model)
      .subscribe(success => {
        if (success) {
          this.isSuccessful = true;
          this.passwordChangeStatus = "Your password has been changed. To get started, select 'Sign in' below.";
        }
        else {
          this.passwordChangeStatus = "There was an error resetting your password. Try again or contact the support team for help.";
        }
      });
  }

}
