<h1>Account Profile</h1>
<!-- <div class="nsw-h4">Review and update your account profile information.</div> -->

<h2>Account Details</h2>
<p>Review and update your account profile information.</p>

<div class="nsw-col nsw-col-md-6">
  <form #profileForm="ngForm" (ngSubmit)="profileForm.valid && save(model)" class="form-horizontal" id="profileForm"
    role="form" name="profileForm">

    <div class="nsw-form__group">
      <label class="nsw-form__label nsw-form__required" for="firstName">First name</label>
      <input type="text" id="firstName" name="firstName" class="nsw-form__input" #firstName="ngModel"
        [(ngModel)]="model.firstName" placeholder="First name" required>
      <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched) && firstName.errors?.['required']"
        class="nsw-form__helper nsw-form__helper--error">
        <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
        <span>First name is required.</span>
      </div>
    </div>

    <div class="nsw-form__group">
      <label class="nsw-form__label nsw-form__required" for="firstName">Last name</label>
      <input type="text" id="lastName" name="lastName" class="nsw-form__input" #lastName="ngModel"
        [(ngModel)]="model.lastName" placeholder="Last name" required>
      <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched) && lastName.errors?.['required']"
        class="nsw-form__helper nsw-form__helper--error">
        <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
        <span>Last name is required.</span>
      </div>
    </div>

    <!-- <div class="nsw-form__group">
        <label class="nsw-form__label nsw-form__required" for="organisation">Organisation</label>
        <input type="text" id="organisation" name="organisation" class="nsw-form__input"
          #organisation="ngModel" [(ngModel)]="model.organisation" placeholder="Organisation" required>
        <div
          *ngIf="organisation.invalid && (organisation.dirty || organisation.touched) && organisation.errors?.['required']"
          class="nsw-form__helper nsw-form__helper--error">
          <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
          <span>Organisation is required.</span>
        </div>
      </div> -->

    <div class="nsw-form__group">
      <label class="nsw-form__label">Email</label>
      <span>{{model.email}}</span>
    </div>

    <div class="nsw-form__group">
      <label class="nsw-form__label nsw-form__required" for="phone">Phone</label>
      <input type="text" id="phone" name="phone" class="nsw-form__input" #phone="ngModel" [(ngModel)]="model.phone"
        placeholder="Phone" required>
      <div *ngIf="phone.invalid && (phone.dirty || phone.touched) && phone.errors?.['required']"
        class="nsw-form__helper nsw-form__helper--error">
        <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
        <span>Phone is required.</span>
      </div>
    </div>

    <div class="nsw-form__group">
      <input type="submit" class="nsw-button nsw-button--dark" value="Save" aria-label="Save" />
      <div class="pt-5">
        <div class="nsw-form__helper nsw-form__helper--valid"
          *ngIf="isSuccessful && profileChangeStatus != null && profileChangeStatus.length > 0">
          <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
          {{profileChangeStatus}}
        </div>
        <div class="nsw-form__helper nsw-form__helper--error"
          *ngIf="!isSuccessful && profileChangeStatus != null && profileChangeStatus.length > 0">
          <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
          {{profileChangeStatus}}
        </div>
      </div>
    </div>
  </form>
</div>


<h2>Change Password</h2>
<p>Provide your current and new password.</p>

<app-change-password></app-change-password>

