<app-header></app-header>
<div class="nsw-container pb-5">
  <div class="nsw-layout">
      <main class="nsw-layout__main" id="main-content">
          <!-- START: Main content -->
          <router-outlet></router-outlet>
          <!-- END: Main content -->
      </main>
  </div>
</div>
<app-footer></app-footer>