import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PortalModule } from 'src/app/_models/portal-module';
import { PortalModuleService } from 'src/app/portal-modules/_services/portal-module.service';
import { AuthorisationService } from '../../_services/authorisation.service';
import { UserModule } from '../../_models/user-module';
import { SessionRequest } from 'src/app/_models/session-request';
import { SessionService } from 'src/app/_services/session.service';
import { AuthService } from 'src/app/_services/auth.service';
import { User } from 'src/app/_models/user';
import { UserService } from 'src/app/_services/user.service';
import { UserAdminService } from 'src/app/admin/_services/user-admin.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-portal-module-table',
  templateUrl: './portal-module-table.component.html',
  styleUrl: './portal-module-table.component.scss'
})
export class PortalModuleTableComponent {

  @Input() public userId: number = 0;

  dataSource?: PortalModule[] = undefined;
  displayedColumns: string[] = ['name', 'actions'];
  isLoadingResults = true;

  allActiveModules?: PortalModule[];
  user?: User;

  constructor(
    private moduleService: PortalModuleService,
    private sessionService: SessionService,
    private authService: AuthService,
    private userAdminService: UserAdminService,
    private authorisationService: AuthorisationService,
    private router: Router,
    @Inject(DOCUMENT) private readonly documentRef: Document
  ) { }

  ngOnInit(): void {
    if (!this.dataSource) {
      this.isLoadingResults = true;
    }
    this.getUser();
    this.loadModules();
  }

  loadModules(): void {
    if (this.userId > 0) {
      this.isLoadingResults = true;

      this.moduleService.getAllModules()
        .subscribe({
          next: allModules => {
            this.allActiveModules = allModules;
            this.getUserModules();
          }
        });
    }
  }

  getUser(): void {
    this.userAdminService.getUserById(this.userId)
      .subscribe(data => {
        this.user = data;
      });
  }

  getUserModules(): void {
    this.moduleService.getUserModules(this.userId)
      .subscribe({
        next: userModules => {
          this.setUserModuleDataSource(userModules);
          this.isLoadingResults = false;
        }
      });
  }

  setUserModuleDataSource(userModules: UserModule[]): void {
    if (this.allActiveModules) {
      this.allActiveModules.forEach(o => {
        var temp = userModules.filter(x => x.moduleId == o.id)[0];
        if (temp) {
          o.userId = temp.userId;
          o.clientId = temp.clientId;
        }
      });
      this.dataSource = this.allActiveModules;
    }
  }

  addModule(moduleId: number): void {
    var module = new UserModule();
    module.userId = this.userId;
    module.moduleId = moduleId;

    this.authorisationService.grantAccess(module)
      .subscribe({
        next: result => {
          if (result) {
            this.getUserModules();
          }
          else {
            alert("There was an issue in granting the user access to the module.");
          }
        }
      });
  }

  removeModule(moduleId: number): void {
    var module = new UserModule();
    module.userId = this.userId;
    module.moduleId = moduleId;

    this.authorisationService.revokeAccess(module)
      .subscribe({
        next: result => {
          if (result) {
            this.allActiveModules = [];
            this.loadModules();
          }
          else {
            alert("There was an issue in revoking the module from the user.");
          }
        }
      });
  }

  goToClientPermissionEndpoint(moduleId: number, clientId: string): void {
    this.moduleService.getClientPermissionEndpoint(moduleId)
    .subscribe({
      next: (permissionUrl: string) => {
        if (permissionUrl) {

          //add email to end of permissionUrl.
          let url = permissionUrl.replace("{0}", this.user?.email!);

          //call getClientEndpoint to get a sessionToken and set returnUrl.
          //use logic of auth login redirect.
          let request = new SessionRequest();
          request.clientId = clientId ?? "";
          request.returnUrl = url ?? "";
          request.token = "";

          this.sessionService.getSessionResponse(request)
            .subscribe(response => {
              if (response.returnUrl) {
                //window.location.href = response.returnUrl;
                this.documentRef?.defaultView?.open(response.returnUrl, "_blank");
              }
              else {
                alert("There was an issue getting the permissions url.");
              }
            })
        }
        else {
          // Must be CZA
          this.router.navigate(["admin/users/modulepermission", this.userId, moduleId]);
          // alert("There was an issue in revoking the module from the user.");
        }
      }
    });
  }
}
