import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicLayoutComponent } from './_layouts/public-layout/public-layout.component';
import { AuthenticatedLayoutComponent } from './_layouts/authenticated-layout/authenticated-layout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardService } from './_guards/auth-guard.service';
import { RoleGuardService } from './_guards/role-guard.service';

const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);

const routes: Routes = [
  {
    path: '',
    component: PublicLayoutComponent,
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'admin',
        // component: AuthenticatedLayoutComponent, // implement this in the admin module if layout is different
        canActivate: [RoleGuardService],
        data: {
          expectedRole: 'System Administrator,Administrator'
        },
        loadChildren: adminModule
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
