import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator, ValidatorFn } from '@angular/forms';


export function passwordStrengthValidator(passwordRegEx: RegExp): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = passwordRegEx.test(control.value);
    return isValid ? null : { passwordStrength: { value: control.value } };
  };
}


@Directive({
  selector: '[appPasswordStrength]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PasswordStrengthDirective, multi: true }]
})
export class PasswordStrengthDirective implements Validator {

  regExRule: string = "^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{12,}$";

  validate(control: AbstractControl): ValidationErrors | null {
    // consider empty models to be valid
    if (!control.value)
      return null;

    return passwordStrengthValidator(new RegExp(this.regExRule))(control);
  }
}
