import { SessionResponse } from "./session-response";

export class AuthResponse {
    id!: number;
    email!: string;
    role?: string;

    isVerified!: boolean;
    isAccountLocked!: boolean;
    isAuthenticated!: boolean;
    isTwoFactorEnabled!: boolean;
    isTwoFactorVerified!: boolean;

    jwtToken!: string;
    jwtTokenExpiration!: Date;

    sessionResponse?: SessionResponse;
}
