import { catchError, of } from 'rxjs';
import { AuthService } from '../_services/auth.service';

export function appInitializer(authService: AuthService) {
    const jwtToken = authService.getJwtToken();
    if (jwtToken) {
        //dont call refreshToken endPoint if the accessToken isnt due to expire within 1 minute.
        const expires = new Date(jwtToken.exp * 1000);

        // console.log("expires - 1min => " + (expires.getTime() - (60 * 1000)));
        // console.log("now() => " + Date.now());
        // console.log("(expires.getTime() - (60 * 1000)) > Date.now() => " + ((expires.getTime() - (60 * 1000)) > Date.now()));
        // console.log("");

        // if the token expiry minus 1 minute is still greater that the current time, 
        // then there is no point in refreshing the token just yet.
        if ((expires.getTime() - (60 * 1000)) > Date.now())
            return () => of();
    }

    return () => authService.getRefreshToken()
        .pipe(
            // catch error to start app on success or failure
            catchError(() => of())
        );
}