import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  loggedIn(): boolean {
    return this.authService.isAuthenticated();
  }

  logout(): void {
    this.authService.logout()
      .subscribe({
        next: () => {
          this.router.navigate(["/"]);
        },
        error: err => {
        },
        complete: () => {
        }
      });
  }

  isAdmin(): boolean {
    return this.authService.userHasAnyRole('System Administrator', 'Administrator');
  }
}
