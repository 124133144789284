import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { jwtDecode } from 'jwt-decode';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {

  constructor(
    public authService: AuthService,
    public router: Router,
    private location: Location) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // this will be passed from the route config on the data property
    let expectedRole: any;
    let hasRole = false;
    const authenticated: boolean = this.authService.isAuthenticated();

    if (authenticated) {
      if (route.data['expectedRole']) {
        expectedRole = route.data['expectedRole'].split(",");
      }

      const token: any = localStorage.getItem(this.authService.getAccessTokenName());

      if (token) {
        // decode the token to get its payload
        const tokenPayload: any = jwtDecode(token);

        expectedRole.forEach((role: any) => {
          if (role) {
            const payload = tokenPayload["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];

            let payloadRoles: string[] = [];

            if (Array.isArray(payload)) {
              payloadRoles = payload;
            } else {
              payloadRoles.push(payload);
            }

            const roleFound: boolean = payloadRoles != null ? payloadRoles.indexOf(role) !== -1 : false;
            if (roleFound) {
              hasRole = true;
            }
          }
        });
      }
    }

    if (!authenticated || !hasRole) {
      //this.router.navigate(["login"]);
      alert('You are not authorized to view this page');
      this.location.back();
      return false;
    }

    return authenticated && hasRole;
  }
}
