import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthResponse } from 'src/app/_models/auth-response';
import { Login } from 'src/app/_models/login';
import { AuthService } from 'src/app/_services/auth.service';
import { SessionRequest } from '../../_models/session-request';
import { SessionService } from 'src/app/_services/session.service';
import { SessionResponse } from 'src/app/_models/session-response';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  Error: string = '';
  HasError: boolean = false;
  IsLoggingIn: boolean = false;
  LoginModel = new Login();
  LoginResponse?: AuthResponse = undefined;
  MfaCodeSent: boolean = false;
  IsLoggedIn = false;
  IsLoginFormDisabled: boolean = false;

  constructor(
    private authService: AuthService,
    private sessionService: SessionService,
    private router: Router,
    private route: ActivatedRoute) { }
  // private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit(): void {

    //if a returnUrl is present, the sso client will auto redirect back to this url instead of the sso portal.
    this.route.paramMap.subscribe({
      next: params => {
        //let clientId = params.get('cid');
        //let returnUrl = params.get('returnUrl');

        let clientId = this.route.snapshot.queryParamMap.get('cid');
        let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl');

        this.LoginModel.sessionRequest = new SessionRequest();
        this.LoginModel.sessionRequest.clientId = clientId ?? "";
        this.LoginModel.sessionRequest.returnUrl = returnUrl ?? "";
        this.LoginModel.sessionRequest.token = "";

        if (this.authService.isAuthenticated()) {

          this.IsLoggedIn = true;

          if (clientId) {
            //call server to get returnUrl just like what would happen on login.
            this.getSessionResponse(this.LoginModel.sessionRequest);
          }
          else if (!clientId) {
            this.router.navigate(["/dashboard"]);
          }
        }
      }
    });
  }

  getSessionResponse(request: SessionRequest): void {
    this.sessionService.getSessionResponse(request)
      .subscribe(response => {
        if (response) {
          this.goToDestination(response);
        }
      });
  }

  // reCAPTCHA v3 method
  executeRecaptcha(): void {
    // this.recaptchaV3Service.execute('login')
    //   .subscribe((token: string) => {
    //     this.model.Recaptcha_token = token;
    this.LoginModel.recaptcha_token = 'test';
    this.login();
    //   });
  }

  // reCAPTCHA v3 method for 2fa workflow.
  executeRecaptcha2FA(): void {
    // this.recaptchaV3Service.execute('login')
    //   .subscribe((token: string) => {
    //     this.LoginModel.Recaptcha_token = token;
    this.LoginModel.recaptcha_token = 'test';
    this.login2FA();
    //  });
  }

  // reCAPTCHA v3 method for 2fa workflow.
  executeRecaptchaResendMfaCode(): void {
    // this.recaptchaV3Service.execute('login')
    //   .subscribe((token: string) => {
    //     this.LoginModel.Recaptcha_token = token;
    this.LoginModel.recaptcha_token = 'test';
    this.resendMfaCode();
    //  });
  }

  login(): void {
    this.LoginResponse = undefined;
    this.IsLoggingIn = true;
    this.HasError = false;

    this.authService.login(this.LoginModel)
      .subscribe({
        next: (response: AuthResponse) => {
          this.LoginResponse = response;

          if (response.isAuthenticated
            && !response.isAccountLocked
            && !response.isTwoFactorEnabled) {
            this.goToDestination(response.sessionResponse);
          }

          if (!response.isAuthenticated) {
            this.HasError = true;
            this.Error = "Incorrect email/password combination.";
          }

          if (!response.isAuthenticated) {
            this.HasError = true;
            this.Error = "Your acount has been locked. Click 'Forgot password' to reset your password or try again later.";
          }
        },
        error: err => {
          this.HasError = true;
          if (err.status == 403) {
            this.Error = "Your acount has been locked. Click 'Forgot password' to reset your password or try again later.";
          }
          else {
            //eg. err.status == 401 - unauthorised, or internal error.
            this.Error = "Incorrect email/password combination.";
          }

          this.LoginResponse = new AuthResponse();
          this.LoginResponse.isAuthenticated = false;
          this.LoginResponse.isTwoFactorEnabled = false;
          this.LoginResponse.isAccountLocked = err.status == 403;
          this.IsLoggingIn = false;
        },
        complete: () => {
          this.IsLoggingIn = false;
        }
      });
  }

  login2FA(): void {
    this.IsLoggingIn = true;

    this.authService.login2FA(this.LoginModel)
      .subscribe({
        next: response => {
          this.LoginResponse = response;

          if (response.isAuthenticated && !response.isAccountLocked &&
            response.isTwoFactorEnabled && response.isTwoFactorVerified) {
            this.goToDestination(response.sessionResponse);
          }
        },
        error: err => {
          this.LoginResponse = new AuthResponse();
          this.LoginResponse.isAuthenticated = false;
          this.LoginResponse.isTwoFactorEnabled = true;
          this.LoginResponse.isAccountLocked = err.status == 403;
          this.IsLoggingIn = false;
        },
        complete: () => {
          this.IsLoggingIn = false;
        }
      });
  }

  resendMfaCode(): void {
    this.HasError = false;
    this.LoginResponse = new AuthResponse();
    this.LoginResponse.isAuthenticated = true;
    this.LoginResponse.isAccountLocked = false;
    this.LoginResponse.isTwoFactorEnabled = true;
    this.LoginResponse.isTwoFactorVerified = true;

    this.authService.resendMfaCode(this.LoginModel)
      .subscribe({
        next: success => {
          this.MfaCodeSent = success;
        },
        error: err => {
          this.HasError = true;
          this.Error = "There was an issue resending an MFA Code.";
        },
        complete: () => {
        }
      });
  }

  goToDestination(response?: SessionResponse): void {
    if (response?.returnUrl) {
      if (this.authService.needsPasswordChange()) {
        this.router.navigate(["/profile/changepassword"], { queryParams: { returnUrl: response?.returnUrl } });
      }
      else {
        // the returnUrl is present and user doesnt need to
        // change their password so redirect back to calling app.
        window.location.href = response?.returnUrl;
      }
    }
    else {
      //open portal since no return url present.
      //auth-guard canActivate should check for and enforce password change if required.
      this.router.navigate(["/dashboard"]);
    }
  }
}
