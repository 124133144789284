<div class="nsw-col nsw-col-md-6">
    <h1>Logout</h1>
  
    <div class="nsw-form__group">
      <div *ngIf="isLoggedIn && isLoggingOut">
        <p>Please wait while we log you out...</p>
        <div class="spinner-wrapper" *ngIf="isLoggingOut">
            <mat-spinner style="margin:0 auto;" diameter="30"></mat-spinner>
          </div>
      </div>
  
      <div *ngIf="!isLoggedIn">
        <p>You have been successfully logged out.</p>
      </div>
  
      <div *ngIf="hasError">
        <p>There was an issue trying to log you out.</p>
      </div>
    </div>
  
    <div class="nsw-form__group">
      <a class="nsw-button nsw-button--dark" [routerLink]="['/dashboard']" *ngIf="isLoggedIn">Go to Dashboard</a>
      <a class="nsw-button nsw-button--dark" [routerLink]="['/login']" *ngIf="!isLoggedIn">Log in</a>
    </div>
  </div>
