import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { baseUrl } from 'src/environments/environment';
import { UserListViewModel } from '../_models/user-list-view-model';
import { User } from 'src/app/_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserAdminService {

  serviceUrl = baseUrl + '/users';

  constructor(
    private http: HttpClient,
  ) { }

  getUserList(): Observable<UserListViewModel[]> {
    return this.http.get<UserListViewModel[]>(this.serviceUrl + '/getAllUsersWithRoles');
  }

  getUserById(userId: number): Observable<User> {
    return this.http.get<User>(this.serviceUrl + '/getUserById', {
      params: {
        userId: userId
      }
    });
  }

  createUser(model: User): Observable<number> {
    return this.http.post<number>(this.serviceUrl + '/create', model);
  }

  updateUser(model: User): Observable<boolean> {
    return this.http.post<boolean>(this.serviceUrl + '/update', model);
  }

  deleteUser(userId: number): Observable<boolean> {
    return this.http.get<boolean>(this.serviceUrl + '/delete', {
      params: {
        userId: Number(userId)
      }
    });
  }

  getUsersByModule(moduleId: number): Observable<UserListViewModel[]> {
    return this.http.get<UserListViewModel[]>(this.serviceUrl + '/getUsersByModule', {
      params: {
        moduleId: moduleId
      }
    });
  }
}
