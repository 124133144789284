import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPassword } from 'src/app/_models/user';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  
  model: ResetPassword = new ResetPassword();
  isSuccessful = false;
  passwordChangeStatus: string = "";

  returnUrl?: string;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    //if a returnUrl is present, the sso client will auto redirect back to this url instead of the sso portal.
    this.route.paramMap.subscribe({
      next: params => {
        this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') ?? undefined;
      }
    });
  }

  changePassword(model: ResetPassword): void {
    this.isSuccessful = false;

    this.authService.changePassword(model)
      .subscribe({
        next: (result) => {
          if (result != null) {
            //remove old token.
            this.authService.clearCurrentUser();
            // localStorage.removeItem(this.authService.getAccessTokenName());
            // localStorage.removeItem(this.authService.getExpirationTokenName());

            //add new token.
            localStorage.setItem(this.authService.getAccessTokenName(), result.token);
            localStorage.setItem(this.authService.getExpirationTokenName(), result.expiration);

            this.isSuccessful = true;
            this.passwordChangeStatus = "Your password has been changed.";

            this.redirectToReturnUrl(this.returnUrl);
          }
          else {
            this.passwordChangeStatus = 'Your old password was incorrect or the new password doesnt meet the criteria.';
          };
        },
        error: (error) => {
          this.isSuccessful = false;
          this.passwordChangeStatus = 'There was an issue. Check your old password is incorrect and the new password meets the criteria.';
        },
        complete: () => {
        }
      });
  }

  redirectToReturnUrl(returnUrl?: string): void {
    if (returnUrl) {
      // the returnUrl is present so redirect back to calling app.
      window.location.href = returnUrl;
    }
    else {
      this.router.navigate(["/dashboard"]);
    }
  }

}
