<h1>Sustainable Government Portal Log In</h1>
<!-- <p class="nsw-intro">Login here to access the suite of Impact Pathways Modules including the CASPER application and the
  Carbon Zero Accelerator application.</p> -->
<p class="nsw-intro" *ngIf="LoginResponse == null || LoginResponse.isAccountLocked || !LoginResponse.isTwoFactorEnabled">Log in here to access the Net Zero Accelerator and CASPER applications.</p>
<br />
<div class="nsw-col nsw-col-md-6">
  <div  *ngIf="IsLoggedIn">
    <span class="nsw-h2">Logging in</span>&nbsp;
    <div class="spinner-wrapper" *ngIf="IsLoggingIn">
      <mat-spinner style="margin:0 auto;" diameter="30"></mat-spinner>
    </div>
  </div>
  <form #loginForm="ngForm" id="loginForm" role="form" class="nsw-form" *ngIf="!IsLoggedIn">

    <!-- user credentials workflow -->
    <div *ngIf="LoginResponse == null || LoginResponse.isAccountLocked || !LoginResponse.isTwoFactorEnabled ">
      <div class="nsw-form__group">
        <label class="nsw-form__label nsw-form__required" for="username">Email</label>
        <input type="email" class="nsw-form__input" id="username" name="username" placeholder="Email address"
          [disabled]="IsLoginFormDisabled" [(ngModel)]="LoginModel.username" required
          [attr.aria-invalid]="loginForm.controls['username'] && loginForm.controls['username'].invalid && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)" />
        <span
          *ngIf="loginForm.controls['username']?.invalid && (loginForm.controls['username'].dirty || loginForm.controls['username'].touched)"
          class="nsw-form__helper nsw-form__helper--error" id="form-text-3-error-text">
          <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
          This field is required
        </span>
      </div>

      <div class="nsw-form__group">
        <label class="nsw-form__label nsw-form__required" for="password">Password</label>
        <input type="password" class="nsw-form__input" id="password" name="password" placeholder="Password"
          [disabled]="IsLoginFormDisabled" [(ngModel)]="LoginModel.password" required
          [attr.aria-invalid]="loginForm.controls['password'] && loginForm.controls['password'].invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)" />
        <span
          *ngIf="loginForm.controls['password']?.invalid && (loginForm.controls['password'].dirty || loginForm.controls['password'].touched)"
          class="nsw-form__helper nsw-form__helper--error" id="form-text-3-error-text">
          <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
          This field is required
        </span>
      </div>

      <div class="nsw-form__group">
        <input type="checkbox" class="nsw-form__checkbox-input" id="remember-me" name="RememberMe"
          [(ngModel)]="LoginModel.rememberMe" [disabled]="IsLoginFormDisabled" />
        <label for="remember-me" class="nsw-form__checkbox-label">Remember me</label>
      </div>

      <div class="nsw-form__group">
        <a [routerLink]="['/forgotpassword']" style="font-size: small; float: right" aria-label="Forgot Password">Forgot
          password?</a>
      </div>

      <div class="nsw-form__group">
        <input type="button" value="Log in" class="nsw-button nsw-button--dark"
          [disabled]="!loginForm.valid || IsLoggingIn" (click)="executeRecaptcha()">
        <div class="spinner-wrapper" *ngIf="IsLoggingIn">
          <mat-spinner style="margin:0 auto;" diameter="30"></mat-spinner>
        </div>
      </div>
    </div>

    <!-- mfa workflow -->
    <div *ngIf="LoginResponse != null && LoginResponse.isAuthenticated && !LoginResponse.isAccountLocked && LoginResponse.isTwoFactorEnabled">
      <div class="nsw-form__group">
        <label class="nsw-form__label nsw-form__required" for="twoFactorCode">
          Input the security code sent to your email</label>
        <input type="text" class="nsw-form__input" id="twoFactorCode" name="twoFactorCode" placeholder="Two factor code"
          [disabled]="IsLoggingIn" inputmode="numeric" pattern="[0-9]*" #twoFactorCode="ngModel"
          [(ngModel)]="LoginModel.twoFactorCode" required (keyup.enter)="executeRecaptcha2FA()" />
        <span
          *ngIf="loginForm.controls['twoFactorCode']?.invalid && (loginForm.controls['twoFactorCode'].dirty || loginForm.controls['twoFactorCode'].touched)"
          class="nsw-form__helper nsw-form__helper--error" id="form-text-3-error-text">
          <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
          This field is required
        </span>
      </div>

      <div class="nsw-form__group">
        <input type="button" value="Log in" class="nsw-button nsw-button--dark"
          [disabled]="!loginForm.valid || IsLoggingIn" (click)="executeRecaptcha2FA()">
        <div class="spinner-wrapper" *ngIf="IsLoggingIn">
          <mat-spinner style="margin:0 auto;" diameter="30"></mat-spinner>
        </div>
      </div>

      <div class="nsw-form__group">
        <a href="javascript: void(0);" class="link" (click)="executeRecaptchaResendMfaCode()">
          Resend code?
        </a>
        <div class="nsw-in-page-alert nsw-in-page-alert--info nsw-in-page-alert--compact">
          <span class="material-icons nsw-material-icons nsw-in-page-alert__icon" focusable="false"
            aria-hidden="true">info</span>
          <div class="nsw-in-page-alert__content">
            <p class="nsw-small">If you do not receive our email, please check your junk or spam email folders.</p>
          </div>
        </div>
        <div class="nsw-in-page-alert nsw-in-page-alert--info nsw-in-page-alert--compact" *ngIf="MfaCodeSent">
          <span class="material-icons nsw-material-icons nsw-in-page-alert__icon" focusable="false"
            aria-hidden="true">info</span>
          <div class="nsw-in-page-alert__content">
            <p class="nsw-small">A new MFA Code has been sent to your email.</p>
          </div>
        </div>
      </div>
      <div class="nsw-form__helper nsw-form__helper--error" *ngIf="HasError">
        <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">cancel</span>
        {{Error}}
      </div>
    </div>

    <div class="my-3" *ngIf="LoginResponse != null">
      <div class="nsw-in-page-alert nsw-in-page-alert--error nsw-in-page-alert--compact"
          *ngIf="!LoginResponse.isAuthenticated">
        <span class="material-icons nsw-material-icons nsw-in-page-alert__icon" focusable="false" aria-hidden="true">cancel</span>
        <div class="nsw-in-page-alert__content">
          <p class="nsw-small">Incorrect email address or password.</p>
        </div>
      </div>

      <div class="nsw-in-page-alert nsw-in-page-alert--error nsw-in-page-alert--compact"
          *ngIf="LoginResponse.isAuthenticated && LoginResponse.isAccountLocked">
        <span class="material-icons nsw-material-icons nsw-in-page-alert__icon" focusable="false" aria-hidden="true">cancel</span>
        <div class="nsw-in-page-alert__content">
          <p class="nsw-small">Your acount has been locked. Click 'Forgot password' to reset your password or try
            again later.</p>
        </div>
      </div>

      <div class="nsw-in-page-alert nsw-in-page-alert--error nsw-in-page-alert--compact"
          *ngIf="LoginResponse.isAuthenticated && LoginResponse.isTwoFactorEnabled && !LoginResponse.isTwoFactorVerified">
        <span class="material-icons nsw-material-icons nsw-in-page-alert__icon" focusable="false" aria-hidden="true">cancel</span>
        <div class="nsw-in-page-alert__content">
          <p class="nsw-small">The MFA code was not valid. Ensure the code matches the email we sent, otherwise you could try resending a new
            code.</p>
        </div>
      </div>
    </div>
  </form>
</div>
