import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {

  isVerified: boolean = false;
  isLoggedIn: boolean = false;
  //baseUrl: string = "";
  userId?: number = undefined;
  code?: string = undefined;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private route: ActivatedRoute
  ) {}

//https://localhost:4200/verifyemail?userId=794&code=cdd9a0f087dd4f82828c4668372c301d

  ngOnInit(): void {
    // this.activatedRoute.queryParams.subscribe(params => {
    //   this.userId = params["userId"];
    //   this.code = params["code"];
    // });

    this.route.paramMap.subscribe({
      next: params => {
        this.userId = Number(this.route.snapshot.queryParamMap.get('id') ?? 0);
        this.code = this.route.snapshot.queryParamMap.get('code') ?? undefined;
      }
    });

    if (this.userId != null && this.userId > 0 && this.code != null) {
      this.userService.verifyEmail(this.userId, this.code)
        .subscribe({
          next: (data) => {
            if (data) {
              this.isVerified = true;
              this.isLoggedIn = this.authService.isAuthenticated();
            }
          }
        });
    }
  }


}
