<footer class="nsw-footer">
  <!-- <div class="nsw-footer__upper">
      <div class="nsw-container">
          <div class="nsw-footer__group">
              <div class="nsw-footer__heading"><a href="#">Popular</a></div>
              <ul>
                  <li><a href="#">Contact the Premier</a></li>
                  <li><a href="#">Contact a Minister</a></li>
                  <li><a href="#">About NSW</a></li>
                  <li><a href="#">State flag</a></li>
                  <li><a href="#">State Funerals</a></li>
                  <li><a href="#">Buy Regional</a></li>
                  <li><a href="#">Life events</a></li>
                  <li><a href="#">NSW Government directory</a></li>
                  <li><a href="#">Service NSW locations</a></li>
              </ul>
          </div>
          <div class="nsw-footer__group">
              <div class="nsw-footer__heading"><a href="#">What's happening</a></div>
              <ul>
                  <li><a href="#">News</a></li>
                  <li><a href="#">Ministerial media releases</a></li>
                  <li><a href="#">Projects and initiatives</a></li>
                  <li><a href="#">Have your say</a></li>
                  <li><a href="#">NSW school and public holidays</a></li>
                  <li><a href="#">Find a job in NSW Government</a></li>
                  <li><a href="#">I work for NSW</a></li>
              </ul>
          </div>
          <div class="nsw-footer__group">
              <div class="nsw-footer__heading"><a href="#">Departments</a></div>
              <ul>
                  <li><a href="#">Customer Service</a></li>
                  <li><a href="#">Communities and Justice</a></li>
                  <li><a href="#">Education</a></li>
                  <li><a href="#">Health</a></li>
                  <li><a href="#">Planning, Industry and Environment</a></li>
                  <li><a href="#">Premier and Cabinet</a></li>
                  <li><a href="#">Regional NSW</a></li>
                  <li><a href="#">Transport</a></li>
                  <li><a href="#">Treasury</a></li>
              </ul>
          </div>
          <div class="nsw-footer__group">
              <div class="nsw-footer__heading"><a href="#">NSW Government</a></div>
              <ul>
                  <li><a href="#">The Premier</a></li>
                  <li><a href="#">NSW Ministers</a></li>
                  <li><a href="#">Find your local Member of Parliament</a></li>
                  <li><a href="#">Boards and Committees</a></li>
                  <li><a href="#">NSW Government communications</a></li>
                  <li><a href="#">Unsolicited proposals</a></li>
                  <li><a href="#">Premier's Priorities</a></li>
              </ul>
          </div>
      </div>
  </div> -->
  <div class="nsw-footer__lower">
      <div class="nsw-container">
          <p>We pay respect to the Traditional Custodians and First Peoples of NSW, and acknowledge their continued
              connection to their country and culture.</p>
          <hr>
          <ul>
              <li><a href="https://www.energy.nsw.gov.au/accessibility">Accessibility</a></li>
              <li><a href="https://www.energy.nsw.gov.au/copyright">Copyright</a></li>
              <li><a href="https://www.energy.nsw.gov.au/disclaimer">Disclaimer</a></li>
              <li><a href="https://www.energy.nsw.gov.au/privacy">Privacy</a></li>
              <!-- <li><a href="#">Content sources</a></li> -->
              <!-- <li><a href="#">RSS</a></li> -->
              <li><a href="https://www.energy.nsw.gov.au/contact">Contact us</a></li>
              <li><a [routerLink]="['/portal']" [routerLinkActive]="['active']" aria-label="Admin">Admin</a></li>
          </ul>
          <div class="nsw-footer__info">
              <div class="nsw-footer__copyright">Copyright © {{ date | date : 'yyyy'}}</div>
              <!-- <div class="nsw-footer__built">Built by Bynar Computing</div> -->
          </div>
      </div>
  </div>
</footer>
