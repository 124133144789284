<h1>Change Password</h1>

<div class="nsw-in-page-alert nsw-in-page-alert--warning" *ngIf="isForcePassword">
  <span class="material-icons nsw-material-icons nsw-in-page-alert__icon" focusable="false" aria-hidden="true">error</span>
  <div class="nsw-in-page-alert__content">
    <p class="nsw-h5">Password must be changed</p>
    <p>Your password must be changed before continuing.</p>
  </div>
</div>


<p>Provide your current and new password.</p>


<app-change-password></app-change-password>
