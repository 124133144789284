import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { PortalModuleTableModule } from '../portal-modules/_components/portal-module-table/portal-module-table.module';
import { RouterModule } from '@angular/router';
import { MatProgressSpinner } from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    DashboardComponent
  ],
  imports: [
    CommonModule,
    PortalModuleTableModule,
    RouterModule,
    MatProgressSpinner
  ]
})
export class DashboardModule { }
