import { Component } from '@angular/core';
import { UserRegisterModel } from 'src/app/_models/user';
import { AuthService } from 'src/app/_services/auth.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {

  model: UserRegisterModel = new UserRegisterModel();
  isSuccessful = false;
  profileChangeStatus: string = "";

  constructor(
    private userService: UserService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.load();
  }

  load(): void {
    this.userService.getUserProfile()
      .subscribe({
        next: data => {
          this.model = data;
        }
      });
  }

  save(model: UserRegisterModel): void {
    this.isSuccessful = false;

    this.userService.updateUser(model)
      .subscribe({
        next: (result) => {
          if (result != null) {
            this.isSuccessful = true;
            this.profileChangeStatus = "Your profile has been changed.";
          }
          else {
            this.profileChangeStatus = 'There was an issue and your profile was not saved.';
          };
        },
        error: (error) => {
          this.isSuccessful = false;
          this.profileChangeStatus = 'There was an error and your profile was not saved.';
        },
        complete: () => {
        }
      });
  }
}
