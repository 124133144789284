import { Component } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-change-account-password',
  templateUrl: './change-account-password.component.html',
  styleUrl: './change-account-password.component.scss'
})
export class ChangeAccountPasswordComponent {

isForcePassword = false;

  constructor(private authService: AuthService) { }

  ngOnInit(){
    this.isForcePassword = this.authService.needsPasswordChange();
  }

}
