import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrl: './logout.component.scss'
})
export class LogoutComponent {

  isLoggedIn: boolean = false;
  isLoggingOut: boolean = true;
  hasError: boolean = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activate();  
    this.logout();
  }

  activate(): void {
    this.isLoggedIn = this.authService.isAuthenticated();
    this.isLoggingOut = true;

    if (!this.isLoggedIn) {
      this.router.navigate(["/"]);
    }
  }

  logout(): void {
    this.isLoggingOut = true;

    this.authService.logout()
      .subscribe({
        next: () => {
          this.isLoggedIn = false;
          this.isLoggingOut = false;
          //this.router.navigate(["/"]);
        },
        error: err => {
          this.isLoggedIn = true;
          this.isLoggingOut = false;
        },
        complete: () => {
          this.isLoggedIn = false;
          this.isLoggingOut = false;
        }
      });
  }
}
