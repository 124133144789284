<!-- <div class="nsw-global-alert js-global-alert" role="alert">
  <div class="nsw-global-alert__wrapper">
    <div class="nsw-global-alert__content">
      <div class="nsw-global-alert__title">Rebate Swap for Solar offer closed</div>
      <p>Applications for the Rebate Swap for Solar offer closed on Friday 5 April 2024.</p>
      <button class="nsw-icon-button js-close-alert" type="button" aria-expanded="true">
        <span class="material-icons nsw-material-icons" focusable="false" aria-hidden="true">close</span>
        <span class="sr-only">Close message</span>
      </button>
    </div>
  </div>
</div> -->

<app-header></app-header>
<div class="nsw-container">
  <div class="nsw-layout">
      <main class="nsw-layout__main" id="main-content">
          <!-- START: Main content -->
          <router-outlet></router-outlet>
          <!-- END: Main content -->
      </main>
  </div>
</div>
<app-footer></app-footer>
<!-- <div class="to-top show"><i class="fa fa-angle-double-up"></i></div> -->
